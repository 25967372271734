<template>
  <div class="seat-container" :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <div class="seat-wraper">
      <div class="sign-box" v-show="!searchOk">
        <van-tabs style="margin-bottom:15px;" v-model="active">
          <van-tab title="姓名搜索"></van-tab>
          <van-tab title="单位搜索"></van-tab>
        </van-tabs>
        <van-field v-if="active === 0" label="姓名" v-model="keyword" :border="false" placeholder="请输入您的姓名" maxlength="10">
          <template #label>
            <span>姓</span>
            <span>名</span>
          </template>
        </van-field>
        <div class="input-box" v-if="active === 1">
          <van-field class="van-cell--borderless" label="单位" placeholder="请输入单位名称" v-model="keyword" @input="clicked = false">
            <template #label>
              <span>单</span>
              <span>位</span>
            </template>
          </van-field>
          <div :class="{ 'text-list': true, 'list-show': isShow}">
            <div v-if="filterList.length > 0">
              <van-cell v-for="item in filterList" :key="item" @click="chooseItem(item)">{{ item }}</van-cell>
            </div>
            <span v-else>暂无数据</span>
          </div>
        </div>
        <van-button class="sign-btn" :loading="loading" @click="search">查询</van-button>
      </div>
      <div class="seat-box" v-show="searchOk">
        <div class="seat-item" v-for="(item ,index) in seatList" :key="index">
          <span>您的座位在</span>
          <div style="text-align:center;">{{item.seat}}</div>
        </div>
        <van-button class="sign-btn" @click="searchOk=false">重新查询</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  name: 'seat',
  data() {
    return {
      loading: false,
      searchOk: false,
      seatList: [],
      true: false,
      active: 0,
      filterList: [],
      list: ['国家发改委','省科技厅','省工信厅','国家公安部','国家商务部','省通信管理局','省委网信办','新区党政综合部','新区产业促进局','市委宣传部','市委网信办','高新区','市科技局','市工信局','市商务局','市城管局','市数据资源局','岳麓区委','岳麓区政府','长沙经开区','马栏山','雨花经开区','城发集团','大科城','湘江发展集团','中网联','新湘会','省委办','市委办','湖南联通','湖南电信','浦发银行','远东集团','市广电','京湘会','嘉宾席'],
      keyword: '',
      isShow: false,
      clicked: false
    }
  },
  watch: {
    keyword(val) {
      if (val && !this.clicked) {
        this.filterList = this.list.filter(item => {
          let index = item.indexOf(val)
          return index !== -1
        })
        if (this.filterList.length > 0) {
          this.isShow = true
          this.clicked = false
        } else {
          this.isShow = false
        }
      } else {
        this.isShow = false
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$parent.onChange(0)
    },
    chooseItem(item) {
      this.keyword = item
      this.isShow = false
      this.clicked = true
    },
    chooseCompany() {
      this.show = true
    },
    async search() {
      if (!this.keyword) {
        return
      }
      this.loading = true
      const result = await this.$http.postParams(this.$baseURL.index.getSeatByName(), {
        name: this.keyword
      }, 1)
      this.loading = false
      if (result.code === 200) {
        if (result.data.length === 0) {
          Dialog.alert({
            title: '提示',
            message: '未查询到您的座位信息'
          })
        } else {
          this.searchOk = true
          this.seatList = result.data
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.seat-container{
  height: 100vh;
  overflow: hidden;
  background: url('https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104160018398288556.jpg') center/102% 102% no-repeat;
  animation: scal 4s ease infinite alternate;
  @keyframes scal {
    to{
      background-size: 107% 107%;
    }
  }
  /deep/.van-tabs__line{
    background: @themeColor;
  }
  .seat-wraper{
    position: relative;
    height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    background: rgba(0,0,0,.4);
    .sign-box{
      position: absolute;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
      width: 80%;
      padding: 25px 15px;
      padding-top: 0;
      background: #fff;
      border-radius: 8px;
      .sign-btn{
        width: 100%;
        background: var(--themeColor);
        color: #fff;
        margin-top: 20px;
        border-radius: 8px;
      }
      /deep/.van-field__label{
        width: 60px;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
      }
      /deep/.van-field__control{
        border-bottom: 1px solid #ccc;
        text-align: center;
        font-size: 16px;
      }
      .input-box{
        position: relative;
        .text-list{
          width: 100%;
          position: absolute;
          z-index: 2005;
          border-radius: 5px;
          overflow: hidden;
          box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
          transition: .3s;
          transform: rotateX(90deg);
          opacity: 0;
          transform-origin: top;
          text-align: center;
          font-size: 12px;
          color: #999;
        }
        .list-show{
          transform: rotateX(0);
          opacity: 1;
          // box-shadow: none;
        }
      }
    }
    .seat-box{
      position: absolute;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
      width: 80%;
      max-height: 80%;
      overflow-y: auto;
      // padding: 15px;
      // background: #fff;
      // border-radius: 8px;
      .seat-item{
        width: 100%;
        padding: 25px 15px;
        display: flex;
        flex-direction: column;
        background: #fff;
        opacity: .98;
        border-radius: 8px;
        margin-top: 10px;
        color: @themeColor;
        font-weight: bold;
        font-size: 15px;
        span{
          margin-bottom: 10px;
        }
      }
      .sign-btn{
        width: 100%;
        background: var(--themeColor);
        color: #fff;
        margin-top: 10px;
        border-radius: 8px;
        border: none;
      }
    }
  }
}
</style>